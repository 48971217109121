import React from 'react';
import Header from './components/Header.js';
import Content from './components/Content';
import Wishlist from './components/Wishlist';
import './App.css';

function App() {
  return (
    <main>
      <Header />
      <Content />
      <Wishlist />
    </main>
  );
}

export default App;