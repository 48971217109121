import React from 'react';
import sesanSignature from '../assets/sesan-signature.svg';

function Content() {
  return (
    <section className="content">
      <p>Dearest gentle reader,</p>
      <p>Somehow I've made it to 27. My joints are creaking, my metabolism is slowing, and I'm pretty sure I just yelled at some kids to stop shouting. Since I'm clearly one foot in the grave, why not help make my last years on Earth a little more comfortable?</p>
      <p>Check out my wishlist below. From orthopedic shoes to facial tonic to hide my wrinkles, I've got all the essentials covered. And hey, don't worry if some items seem too expensive - you can always chip in to make it happen! The reserve links take custom amounts :)</p>
      <p>Thanks for helping this old-timer celebrate!</p>
      <img src={sesanSignature} alt="Sesan's signature" className="signature" />
    </section>
  );
}

export default Content;