import React from 'react';

function WishlistItem({ img, title, brand, link, crossedOut }) {
  return (
    <div className={`item ${crossedOut ? 'item-crossed-out' : ''}`}>
      <img src={img} alt={title} />
      <h2>{title}</h2>
      <p>{brand}</p>
      <div className="button-container">
        <a href={link} className="item-button">View Item</a>
        {crossedOut ? (
          <span className="item-button item-button-disabled">Reserve</span>
        ) : (
          <a href="https://revolut.me/olusesanpeter" className="item-button">Reserve</a>
        )}
      </div>
    </div>
  );
}

export default WishlistItem;