import React from 'react';
import WishlistItem from './WishlistItem';
import mxKeys from '../assets/mx-keys-mini.png';
import aesop from '../assets/aesop.png';

const wishlistItems = [
  {
    img: "https://cdn.shopify.com/s/files/1/0569/4937/5153/files/Perfect_Pot_-_Blue_Salt.jpg",
    title: "Perfect Pot",
    brand: "Our place", 
    link: "https://fromourplace.co.uk/products/perfect-pot?"
  },
  {
    img: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/4cfd6849-c0ac-40de-b8b4-5e5637cf3ff7/NIKE+FREE+METCON+6.png",
    title: "Free Metcon 6",
    brand: "Nike",
    link: "https://www.nike.com/gb/t/free-metcon-6-workout-shoes-S70g31/FJ7127-300",
    crossedOut: true
  },
  {
    img: "https://www.phaidon.com/images/9781838666965/main-desktop-x2-webp/9781838666965.webp",
    title: "Now is Better",
    brand: "Stefan Sagmeister",
    link: "https://www.phaidon.com/store/design/now-is-better-9781838666965/",
    crossedOut: true
  },
  {
    img: "https://media.bloomandwild.com/v1/1000x1000/filters:format(webp):quality(75)/https://assets-0.bloomandwild.com/letterbox-main/the-hallie/9f68a837-8b5c-478a-85ef-822fa3fa7d0e.jpg",
    title: "The Hallie",
    brand: "Bloom & Wild",
    link: "https://www.bloomandwild.com/send-flowers/send/the-hallie/1515",
    crossedOut: true
  },
  {
    img: "https://images.ctfassets.net/8cd2csgvqd3m/1s8GwihEtIYPXuQufoORMH/9aaf14d009e6b663c73847bd0a84b789/Packshot-Beosound-A1-2nd-Gen-Green-0090-Perspective-1200x1200px.png?q=90&fm=webp&w=1200&h=1200&fit=fill",
    title: "Beosound A1 2nd Gen",
    brand: "Bang & Olufsen",
    link: "https://www.bang-olufsen.com/en/gb/speakers/beosound-a1?variant=beosound-a1-2nd-green",
    crossedOut: true
  },
  {
    img: aesop,
    title: "Gift card",
    brand: "Aesop",
    link: "https://www.aesop.com/uk/p/kits-travel/gift-kits/digital-gift-card/",
    crossedOut: true
  },
  {
    img: mxKeys,
    title: "MX Keys Mini for Mac",
    brand: "Logitech",
    link: "https://www.logitech.com/en-gb/products/keyboards/mx-keys-mini-mac.910-005777.html",
    crossedOut: true
  },
  {
    img: "https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/apple-tv-4k-hero-select-202210?wid=1076&hei=1070&fmt=jpeg&qlt=90&.v=1664896361408",
    title: "Apple TV 4K",
    brand: "Apple",
    link: "https://www.apple.com/uk/apple-tv-4k/"
  },
  {
    img: "https://images.aceandtate.com/image/upload/c_scale,w_3000/c_fill,h_1920,w_1920,q_85/:spree/public/spree/products/15319/original/anderson-smoke-sun-side.jpg",
    title: "Anderson, Smoke",
    brand: "Ace & Tate",
    link: "https://www.aceandtate.com/gb/anderson-smoke-sun"
  },
  {
    img: "https://www.sunspel.com/cdn/shop/products/mhod1525-bkaa-1_88fdd25e-1c1a-44fa-bdd2-23be6eb7ad10.jpg?v=1675852255&width=2160",
    title: "Black Loopback Hoodie",
    brand: "Sunspel",
    link: "https://www.sunspel.com/products/mens-cotton-loopback-overhead-hoody-black-mhod1525"
  },
  {
    img: "https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/apple-watch-series-8-select-2023-hero-202309?wid=1076&hei=1070&fmt=jpeg&qlt=90&.v=1755850185400",
    title: "Apple Watch Series 8",
    brand: "Apple",
    link: "https://www.apple.com/uk/apple-watch-series-8/"
  }
];

function Wishlist() {
  return (
    <section className="wishlist">
      {wishlistItems.map((item, index) => (
        <WishlistItem key={index} {...item} />
      ))}
    </section>
  );
}

export default Wishlist;